<!-- <section class="container" [ngClass]="{ 'menu-expanded': isSideBarExpanded }">
  <aside class="sidebar-area">
    <app-side-bar [isExpanded]="isSideBarExpanded" [showClientContext]="false" (toggle)="toggleSideBar()" />
  </aside>
  <header class="header-area">
    <app-main-header />
  </header>
  <main class="main-area">
    <router-outlet></router-outlet>
  </main>
</section> -->


<div class="wrapper">
	<div class="sidebar" data-color="primary" data-background-color="white" data-image="./assets/img/sidebar-1.jpg">
		<app-sidebar></app-sidebar>
		<!-- <app-side-bar [isExpanded]="isSideBarExpanded" [showClientContext]="true" (toggle)="toggleSideBar()" /> -->
		<div class="sidebar-background" ></div>
	</div>
	<div class="main-panel">
		<!-- <app-main-header /> -->
		<app-navbar></app-navbar>
		<router-outlet></router-outlet>
		<!-- <app-footer></app-footer> -->
	</div>
</div>

