import { InlightsRoute } from '@shared/models/shell/InlightsRoute';

export const authAreaRouteData: InlightsRoute[]  = [
  {
    title: 'Error',
		showInMenu: false,
    path: 'error',
    loadComponent: () =>
      import('./pages/error-page/error-page.component').then(
        (m) => m.ErrorPageComponent,
      ),
			data: { allowAnonymousAccess: true, requiredClaims: []}
  },
  {
		showInMenu: false,
    path: 'callback',
    loadComponent: () =>
      import('./pages/callback-page/callback-page.component').then(
        (m) => m.CallbackPageComponent,
      ),
			data: { allowAnonymousAccess: true, requiredClaims: []}
  },
  {
    title: 'Access Denied',
		showInMenu: false,
    path: 'access-denied',
    loadComponent: () =>
      import('./pages/access-denied/access-denied.component').then(
        (m) => m.AccessDeniedComponent,
      ),
			data: { allowAnonymousAccess: true, requiredClaims: []}
  },
];
