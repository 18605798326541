import { imageJobsRoutes } from '@areas/image-jobs';
import { clientImageTemplatesRouteData } from "./areas/artboards/atrboard.routes";
// import { profileAreaRouteData } from "./areas/profile/route-data";
// import { ClientRoutePlaceholderComponent } from "./shell/client-route-placeholder/client-route-placeholder.component";
import { clientsHomeRouteData } from '@areas/client-home/client-home.routes';
import { clientsAreaRouteData as clientsAreaRoutes } from '@areas/clients/clients.routes';
import { dataSetsTextRoutes } from '@areas/data-sets-text';
import { developerAreaRouteData as developerAreaRoutes } from '@areas/developer/developer.routes';
import { digOMaticRoutes } from '@areas/dig-o-matic/digomatic.routes';
import { imageDefinitionsRouteData } from '@areas/image-definitions/image-definitions.routes';
import { authenticationGuard } from '@shared/guards/authentication.guard';
import { InlightsRoute } from '@shared/models/shell/InlightsRoute';
import { authAreaRouteData as authAreaRoutes } from './auth/auth.routes';
import { ClientContextNullComponent } from './shell/client-context-null/client-context-null.component';
import { ClientContextComponent } from './shell/client-context/client-context.component';
import { imageDefinitionsNewRouteData } from '@areas/image-definitions-new/image-definitions-new.routes';

// Although one may expect loadChildren to be used in the route data, as the imported route segments only lazy load componetns and do not import them, this should still be fine.
// It alos lets us store data that we can use in the navigation service that can be accessed without having to load the route segment.

export const clientRoutes: InlightsRoute[] = [
	...clientsHomeRouteData,
	...imageDefinitionsRouteData,
	...imageDefinitionsNewRouteData,
	...clientImageTemplatesRouteData,
	...imageJobsRoutes,
	...dataSetsTextRoutes,
];

export const nonClientRoutes: InlightsRoute[] = [
	...clientsAreaRoutes,
	...developerAreaRoutes,
	...authAreaRoutes,
	...digOMaticRoutes,
	// { path: 'dig', loadChildren: () => import('@areas/dig-o-matic/digomatic.routes').then(m => m.digOMaticRoutes) },
	{ path: 'tile-planner', loadComponent: () => import('./areas/tile-planner/tile-planner.component').then(m => m.TilePlannerComponent) }
];

export const routes: InlightsRoute[] = [
	{
		title: "Inlights Home",
		path: "",
		component: ClientContextNullComponent,
		children: nonClientRoutes
	},
	{
		path: ":clientID",
		component: ClientContextComponent,
		canActivate: [
			authenticationGuard,
		],
		children: clientRoutes,
		title: 'Client',
		data: {
			allowAnonymousAccess: false,
			requiredClaims: [],
		},
	},
	{ path: "", redirectTo: "clients", pathMatch: "full" },
	// { path: "**", redirectTo: "clients" },

];
