import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NewVersionNotifierComponent } from '../new-version-notifier/new-version-notifier.component';
import { NavbarComponent } from 'app/components/navbar/navbar.component';
import { SidebarComponent } from 'app/components/sidebar/sidebar.component';
import { GlobalClientService } from '@shared/services';

@Component({
	selector: 'app-client-context-null',
	standalone: true,
	imports: [CommonModule,
		RouterOutlet,
		NewVersionNotifierComponent,
		NavbarComponent,
		SidebarComponent,
	],
	templateUrl: './client-context-null.component.html',
	styleUrl: './client-context-null.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientContextNullComponent {
	public isSideBarExpanded = true;
	public toggleSideBar() {
		this.isSideBarExpanded = !this.isSideBarExpanded;
	}

	constructor(private globalClientService: GlobalClientService) {
		this.globalClientService.clearClient()
	}
}
