/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule, Location } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { routes } from 'app/app.routes';
import { filter, map, startWith } from 'rxjs';
import { NavbarItemsComponent } from './navbar-items/navbar-items.component';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	standalone: true,
	imports: [CommonModule, MatIcon, RouterLink, RouterLinkActive, NavbarItemsComponent]
})
export class NavbarComponent implements OnInit {
	private listTitles: any[];
	location: Location;
	mobile_menu_visible: any = 0;
	private toggleButton: any;
	private sidebarVisible: boolean;

	constructor(
		location: Location,
		private element: ElementRef,
		private router: Router,
		private route: ActivatedRoute,
		private auth: AuthService
	) {
		this.location = location;
		this.sidebarVisible = false;
	}

	ngOnInit() {
		this.listTitles = routes.filter((listTitle: any) => listTitle);
		const navbar: HTMLElement = this.element.nativeElement;
		this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
		this.router.events.subscribe((event) => {
			this.sidebarClose();
			var $layer: any = document.getElementsByClassName('close-layer')[0];
			if ($layer) {
				$layer.remove();
				this.mobile_menu_visible = 0;
			}
		});
	}

	sidebarOpen() {
		const toggleButton = this.toggleButton;
		const body = document.getElementsByTagName('body')[0];
		setTimeout(function () {
			toggleButton.classList.add('toggled');
		}, 500);

		body.classList.add('nav-open');

		this.sidebarVisible = true;
	}

	sidebarClose() {
		const body = document.getElementsByTagName('body')[0];
		this.toggleButton.classList.remove('toggled');
		this.sidebarVisible = false;
		body.classList.remove('nav-open');
	}

	sidebarToggle() {
		// const toggleButton = this.toggleButton;
		// const body = document.getElementsByTagName('body')[0];
		var $toggle = document.getElementsByClassName('navbar-toggler')[0];

		if (this.sidebarVisible === false) {
			this.sidebarOpen();
		} else {
			this.sidebarClose();
		}
		const body = document.getElementsByTagName('body')[0];

		if (this.mobile_menu_visible == 1) {
			// $('html').removeClass('nav-open');
			body.classList.remove('nav-open');
			// if ($layer) {
			// 	$layer.remove();
			// }
			setTimeout(function () {
				$toggle.classList.remove('toggled');
			}, 400);

			this.mobile_menu_visible = 0;
		} else {
			setTimeout(function () {
				$toggle.classList.add('toggled');
			}, 430);

			var $layer = document.createElement('div');
			$layer.setAttribute('class', 'close-layer');


			if (body.querySelectorAll('.main-panel')) {
				document.getElementsByClassName('main-panel')[0].appendChild($layer);
			} else if (body.classList.contains('off-canvas-sidebar')) {
				document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
			}

			setTimeout(function () {
				$layer.classList.add('visible');
			}, 100);

			$layer.onclick = () => { //asign a function
				body.classList.remove('nav-open');
				this.mobile_menu_visible = 0;
				$layer.classList.remove('visible');
				setTimeout(function () {
					$layer.remove();
					$toggle.classList.remove('toggled');
				}, 400);
			};

			body.classList.add('nav-open');
			this.mobile_menu_visible = 1;

		}
	}

	public breadCrumbs$ = this.router.events.pipe(
		filter(event => event instanceof NavigationEnd),
		startWith({}),
		map(() => {
			const unfold = (acc: BreadCrumbItem[], route: ActivatedRoute): BreadCrumbItem[] => {
				if (route) {
					const newBreadcrumb = new BreadCrumbItem(acc[acc.length - 1], route.snapshot.url.map(segment => segment.path), route.snapshot.title ?? 'Unknown');
					acc.push(newBreadcrumb);
					return unfold(acc, route.children[0]);
				}
				else {
					return acc;
				}
			}
			return unfold([], this.route);
		})
	);



}

class BreadCrumbItem {
	public allSegments: string[] = [];
	constructor(public parent: BreadCrumbItem, public segments: string[], public title: string) {
		this.allSegments = parent ? parent.allSegments.concat(segments) : ['/'].concat(segments);
	}
}
