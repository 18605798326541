import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import { NavbarDropdownService } from './navbar-dropdown.service';

@Component({
	selector: '[app-navbar-dropdown]', // This will make it render as a <div> element
	standalone: true,
	imports: [CommonModule],
	templateUrl: './navbar-dropdown.component.html',
	styleUrls: ['./navbar-dropdown.component.scss'], // Corrected typo: styleUrl -> styleUrls
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarDropdownComponent {
	@Input() public icon: string = '';
	@Input() public title: string;


	@HostListener('document:click', ['$event'])
	handleClickOutside(event: MouseEvent) {
		if (
			this._status === status.show &&
			this.elementRef.nativeElement != event.target
			&& !this.elementRef.nativeElement.contains(event.target)
		) {
			this.onMenuExpandClick();
		} else {
			this.clicked++;
		}
	}

	@HostListener('mouseleave')
	handleMouseLeave() {
		if (this.clicked > 0 && this._status === status.show) {
			this._updateStatus(status.hiding, status.hidden, 500);
		}
	}

	private clicked = 0;
	private _status = status.hidden;
	private _timeout: number | null = null;
	public statusClass$ = new BehaviorSubject<string>(this._status);

	public toggleClicked$ = this.dropdownService.toggleClicked$.pipe(tap((sender) => {
		if (sender !== this) {
			if (this._timeout) clearTimeout(this._timeout);
			this._updateStatus(status.hiding, status.hidden, 0);
		}
	}));

	constructor(private dropdownService: NavbarDropdownService, private elementRef: ElementRef) {
	}

	public onMenuExpandClick() {
		this.dropdownService.onToggleClicked(this);

		switch (this._status) {
			case status.hidden:
				this._updateStatus(status.showing, status.show, 500);
				break;
			case status.show:
				this._updateStatus(status.hiding, status.hidden, 500);
				break;
			default:
				break;
		}
	}

	private _updateStatus(intermediateStatus: status, finalStatus: status, delay: number) {
		this._status = intermediateStatus;
		this.statusClass$.next(intermediateStatus);
		this._timeout = window.setTimeout(() => {
			this.clicked = 0;
			this._status = finalStatus;
			this.statusClass$.next(finalStatus);
		}, delay);
	}
}

enum status {
	hidden = 'hidden',
	showing = 'showing',
	show = 'show',
	hiding = 'hiding'
}
