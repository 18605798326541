@if({ status: statusClass$ | async}; as model) {

<li class="nav-item dropdown" [class.show]="model.status === 'show' || model.status === 'showing'">
	@if(toggleClicked$ | async) {}
	<a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
		(click)="onMenuExpandClick()" aria-expanded="false">
		<i class="material-icons">{{icon}}</i>
		<ng-content select="[postIcon]"></ng-content>

		<p>
			<span class="d-lg-none d-md-block">{{title}}</span>
		</p>
	</a>
	<div class="dropdown-menu dropdown-menu-right {{statusClass$ | async}}" aria-labelledby="navbarDropdownMenuLink">
		<ng-content></ng-content>
	</div>
</li>
}
