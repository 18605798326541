import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavbarDropdownComponent } from '../navbar-dropdown/navbar-dropdown.component';
import { AuthService } from '@auth0/auth0-angular';
import { CommonModule } from '@angular/common';
import { ThemeService } from 'app/theme.service';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatIcon } from '@angular/material/icon';

@Component({
	selector: '[app-navbar-items]',
	standalone: true,
	imports: [NavbarDropdownComponent, CommonModule, MatSlideToggle, MatIcon],
	templateUrl: './navbar-items.component.html',
	styleUrl: './navbar-items.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarItemsComponent {



	constructor(private auth: AuthService, private themeService: ThemeService) { }

	public isAuthenticated$ = this.auth.isAuthenticated$;

	public onLogout() {
		this.auth.logout({
			logoutParams: { returnTo: `${window.location.origin}` },
		});
	}

	public onLogin() {
		this.auth.loginWithRedirect();
	}

	public onChangeTheme(theme: string | null) {
		this.themeService.setTheme(theme);
	}

	public savedTheme$ = this.themeService.savedTheme$;
}
