import { InlightsRoute } from '@shared/models/shell/InlightsRoute';
import { authorisationGuard } from '../../shared/guards/authorisation.guard';

export const clientsAreaRouteData: InlightsRoute[] = [
	{
		path: 'clients',
		title: 'Clients',
		icon: 'apartment',
		showInMenu: true,
		data: {
			allowAnonymousAccess: false,
			requiredClaims: [{ name: 'clients', value: ['read', 'write'] }],
		},
		canActivate: [authorisationGuard],

		loadComponent: () => import('./pages/client-index/client-index.component').then((m) => m.ClientIndexComponent),
		children: [
			// {
			// 	title: 'Create Client',
			// 	path: 'create',
			// 	loadComponent: () => import('./pages/client-create-page/client-create-page').then((m) => m.ClientCreatePageComponent),
			// 	canActivate: [authenticationGuard, authorisationGuard],
			// 	canDeactivate: [checkForUnsavedChangesGuard],
			// 	data: {
			// 		allowAnonymousAccess: false,
			// 		requiredClaims: [{ name: 'clients', value: ['write'] }],
			// 	},
			// },
			{
				title: 'Client Details',
				path: 'details/:id',
				loadComponent: () => import('./pages/client-details-page/client-details-page').then((m) => m.ClientDetailsPageComponent),
				canActivate: [authorisationGuard],
				// canDeactivate: [checkForUnsavedChangesGuard],
				data: {
					allowAnonymousAccess: false,
					requiredClaims: [{ name: 'clients', value: ['write'] }],
				},
			},
		],
	},
];
