import { AsyncPipe, CommonModule, JsonPipe } from "@angular/common";
import { Component } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatFormField, MatLabel, MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { FormBuilderType } from '@shared/types';
import { Chain } from '@shared/utils/chain';
import { map, tap } from "rxjs";
import { GlobalClientService } from "../../shared/services/clients/global.client.service";

@Component({
	selector: "app-client-selector",
	standalone: true,
	imports: [AsyncPipe, JsonPipe, ReactiveFormsModule, MatSelectModule, MatLabel, MatFormField, CommonModule],
	templateUrl: "./client-selector.component.html",
	styleUrl: "./client-selector.component.scss",
})
export class ClientSelectorComponent {


	isAuthenticated$ = this.auth0Service.isAuthenticated$;

	public clientForm = new FormGroup<FormBuilderType<{ client: string }>>({
		client: new FormControl(null),
	});

	orgs$ = this.clientService.clients$.pipe(
		map(clients => clients.sort((a, b) => a.displayName.localeCompare(b.displayName))),
		map(clients => clients.filter(x => x.active)),
	);

	public selectedOrg$ = this.route.params.pipe(map(x => x['clientID']), tap(x => this.clientForm.patchValue({ client: x })));

	constructor(
		private clientService: GlobalClientService,
		private auth0Service: AuthService,
		private router: Router,
		private route: ActivatedRoute,
	) {

	}

	async onDropdown_Changed(clientId: string) {
		const unfold = (acc: string[], route: ActivatedRoute): string[] =>
			route && Chain.of(route.snapshot.routeConfig?.path).map(x => x === ':clientID' || x?.indexOf(':') === -1).value() ?  // This could also have a boolean on each route to indicate if it can have client changed
				unfold(acc.concat(route.snapshot.url.map(segment => segment.path)), route.children[0]) :
				acc;

		const segments = unfold([], this.route);
		const canChange = true;

		if (canChange) {
			this.router.navigate(["/" + clientId, ...segments.slice(1)]);
			// this.clientForm.patchValue({ client: segments[0] });
		}
	}
}
