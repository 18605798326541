@if (isAuthenticated$ | async; as isAuthenticated) {
@if ({orgs:orgs$ | async, selectedOrg: selectedOrg$ | async}; as model) {
@if (model.orgs?.length??0 > 0) {

<form [formGroup]="clientForm">
	<mat-form-field class="w-full" subscriptSizing="dynamic">
		<mat-select inputId="selectedClient" formControlName="client" (selectionChange)="onDropdown_Changed($event.value)" placeholder="Go to client area">
			<mat-option *ngFor="let org of model.orgs" [value]="org.id">
				{{ org.displayName }}
			</mat-option>
		</mat-select>
	</mat-form-field>
</form>

}
}
}
