<!-- <section   [ngClass]="{ 'menu-expanded': isSideBarExpanded }">
  <aside class="sidebar-area">


  </aside>
  <header class="header-area">
    <app-main-header />
  </header>
  <main class="main-area">
    <router-outlet></router-outlet>
  </main>
</section> -->
@if( {selectedClient:selectedClient$ | async} ; as model) {

<div class="wrapper">
	<div class="sidebar" data-color="primary" data-background-color="white" data-image="./assets/img/sidebar-2.jpg">
		<app-sidebar></app-sidebar>
		<!-- <app-side-bar [isExpanded]="isSideBarExpanded" [showClientContext]="true" (toggle)="toggleSideBar()" /> -->
		<div class="sidebar-background"></div>
	</div>

	<div class="main-panel">
		<!-- <app-main-header /> -->
		<app-navbar></app-navbar>
		@if(model.selectedClient?.clientSelected && model.selectedClient?.success)
		{
		<router-outlet></router-outlet>
		}
		@else if(model.selectedClient?.error)
		{
		<div class="main-content">
			<div class="container-fluid" style="text-align: center; margin-top: 20vh;">
				<h2>{{model.selectedClient?.error}}</h2>
			</div>
		</div>
		} @else {
		<div class="main-content">
			<div class="container-fluid" style="text-align: center; margin-top: 20vh;">
				<h2>Validating Client...</h2>
			</div>
		</div>
		}
		<!-- <app-footer></app-footer> -->
	</div>
</div>
}
