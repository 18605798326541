<nav class="navbar navbar-transparent navbar-absolute fixed-top">
	<div class="container-fluid">
		<div class="navbar-wrapper bread-crumbs">
			@for( item of breadCrumbs$ | async; track $index) {
			@if(item.parent) {
			<mat-icon>chevron_right</mat-icon>
			}
			<a class="navbar-brand" [routerLink]="item.allSegments" routerLinkActive="router-link-active">{{item.title}}</a>
			}
		</div>
		<button mat-raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
			<span class="sr-only">Toggle navigation</span>
			<span class="navbar-toggler-icon icon-bar"></span>
			<span class="navbar-toggler-icon icon-bar"></span>
			<span class="navbar-toggler-icon icon-bar"></span>
		</button>
		<div class="collapse navbar-collapse justify-content-end" id="navigation">
			<ul class="navbar-nav" app-navbar-items>

			</ul>
		</div>
	</div>
</nav>
