import { AuthGuard as authenticationGuard } from '@auth0/auth0-angular';
import { InlightsRoute } from '@shared/models/shell/InlightsRoute';
import { authorisationGuard } from '../../shared/guards/authorisation.guard';

export const clientImageTemplatesRouteData: InlightsRoute[] = [
	{
		title: 'Artboards',
		icon: 'artboard',
		svgIcon: true,
		showInMenu: true,
		path: 'artboards',
		data: {
			allowAnonymousAccess: false,
			requiredClaims: [{ name: 'image-templates', value: ['read', 'write'] }],
		},
		canActivate: [ authorisationGuard],
		loadComponent: () => import('./pages/artboards-index/artboards-index.component').then((m) => m.ArtboardsIndexComponent),

		children: [
			{
				title: 'Artboard Details',
				path: 'details/:id',
				loadComponent: () => import('./pages/artboard-details/artboard-details.component').then((m) => m.ArtboardDetailsComponent),
				canActivate: [authenticationGuard, authorisationGuard],
				// canDeactivate: [checkForUnsavedChangesGuard],
				data: {
					allowAnonymousAccess: false,
					requiredClaims: [{ name: 'image-templates', value: ['write'] }],
				},
			},
		],
	},
];
