import { authorisationGuard } from '@shared/guards';
import { InlightsRoute } from '@shared/models/shell/InlightsRoute';

export const digOMaticRoutes: InlightsRoute[] = [
	{
		title: "Digomatic Dashboard",
		icon: 'work',
		showInMenu: true,
		data: {
			allowAnonymousAccess: false,
			requiredClaims: [{ name: "image-jobs", value: ['read'] }],
		},
		path: 'dig', canActivate: [authorisationGuard], loadComponent: () => import('./dig-omatic-status/dig-omatic-status.component').then(m => m.DigOMaticStatusComponent),
	},
];
