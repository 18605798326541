import { InlightsRoute } from '@shared/models/shell/InlightsRoute';
import { authorisationGuard } from '../../shared/guards/authorisation.guard';

export const developerAreaRouteData: InlightsRoute[] = [
	{
		title: 'Devloper',
		icon: 'code',
		showInMenu: true,
		path: 'claims',
		loadComponent: () => import('./pages/claims-page/claims-page.component').then((m) => m.ClaimsPageComponent,),
		canActivate: [authorisationGuard],
		data: {
			requiredClaims: [{ name: 'developer', value: ['read', 'write'] }],
			allowAnonymousAccess: false,
		},
	},
	{
		title: 'Environment',
		path: 'environment',
		loadComponent: () => import('./pages/environment-page/environment-page.component').then((m) => m.EnvironmentPageComponent,),
		canActivate: [authorisationGuard],
		data: {
			requiredClaims: [{ name: 'developer', value: ['read'] }],
			allowAnonymousAccess: false,
		},
	},
	{
		title: 'Tokens',
		icon: 'code',
		showInMenu: true,
		path: 'tokens',
		loadComponent: () => import('./pages/tokens-page/tokens-page.component').then((m) => m.TokensPageComponent,),
		canActivate: [authorisationGuard],
		data: {
			requiredClaims: [{ name: 'developer', value: ['read', 'write'] }],
			allowAnonymousAccess: false,
		},
	},
	{
		title: 'Error Log',
		icon: 'data_alert',
		showInMenu: true,
		path: 'error-log',
		loadComponent: () => import('./pages/error-log/error-log.component').then((m) => m.ErrorLogComponent),
		canActivate: [authorisationGuard],
		data: {
			requiredClaims: [{ name: 'developer', value: ['read'] }],
			allowAnonymousAccess: false,
		},
	}
];
