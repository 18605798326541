/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { InlightsRoutes } from '@shared/models/shell/InlightsRoute';
import { GlobalClientService } from '@shared/services';
import { NavigationService } from '@shared/services/navigation/navigation.service';
import { nonClientRoutes } from 'app/app.routes';
import { ClientSelectorComponent } from 'app/shell/client-selector/client-selector.component';
import { iif, map, of, switchMap } from 'rxjs';
import { NavbarItemsComponent } from '../navbar/navbar-items/navbar-items.component';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrl: './sidebar.component.scss',
	standalone: true,
	imports: [RouterLink, CommonModule, RouterLinkActive, ClientSelectorComponent, MatIconModule, NavbarItemsComponent]
})
export class SidebarComponent {
	clientItems: InlightsRoutes;
	nonClientItems: InlightsRoutes;

	public selectedClient$ = this.globalClientService.selectedClient$;

	public clientId$ = this.globalClientService.selectedClient$.pipe(map(x => x?.client?.id ?? null));

	constructor(private router: Router, private route: ActivatedRoute, private naviagtionService: NavigationService, private globalClientService: GlobalClientService) {

		// this.clientItems = clientRoutes.filter((route) => route.showInMenu);
		this.nonClientItems = nonClientRoutes.filter((route) => route.showInMenu);

	}

	public clientMenu$ = this.clientId$.pipe(switchMap(x => iif(() => !!x, this.naviagtionService.clientMenu$, of([]))));

	isMobileMenu() {
		return document.body.offsetWidth <= 991;
	}
}
