import { authorisationGuard } from '@shared/guards/authorisation.guard';
import { InlightsRoute } from '@shared/models/shell/InlightsRoute';

export const imageDefinitionsNewRouteData: InlightsRoute[] = [
	{
		title: 'Image Definitions New',
		icon: 'art_track',
		showInMenu: true,
		path: 'image-definitions-new',
		data: {
			allowAnonymousAccess: false,
			requiredClaims: [{ name: 'image-definitions', value: ['read', 'write'] }],
		},
		canActivate: [authorisationGuard],
		loadComponent: () => import('./pages/image-definitions.component').then((m) => m.ImageDefinitionsComponent),
		children: [
			{
				title: 'Image Definition Details',
				showInMenu: false,
				path: 'details/:id',
				loadComponent: () => import('./pages/image-definition-details/image-definition-details.component').then((m) => m.ImageDefinitionDetailsComponent),
				canActivate: [authorisationGuard],
				data: {
					allowAnonymousAccess: false,
					requiredClaims: [{ name: 'image-definitions', value: ['write'] }],
				},
				// children: [{
				// 	title: 'Image Definition Test',
				// 	path: 'test',
				// 	showInMenu: false,
				// 	outlet: 'modalOutlet',
				// 	loadComponent: () => import('./pages/image-definitions.component/image-definition-details/image-definition-test/image-definition-test.component').then((m) => m.ImageDefinitionTestComponent),
				// }]
			},
		]
	},
];
