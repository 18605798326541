
@if( {saveTheme: savedTheme$ | async }; as model) {


<li app-navbar-dropdown [icon]="'brightness_4'" title="Theme Switching">
	<a class="dropdown-item" href="javascript:void(0)" (click)="onChangeTheme(null)" tabindex="0">
		<ng-container *ngTemplateOutlet="radioButtonIcon; context: { $implicit: null, label: 'Use System' }">
		</ng-container>
	</a>
	<a class="dropdown-item" href="javascript:void(0)" (click)="onChangeTheme('dark-m3')" tabindex="0"><ng-container
			*ngTemplateOutlet="radioButtonIcon; context: { $implicit: 'dark-m3', label: 'Dark Mode' }">
		</ng-container></a>
	<a class="dropdown-item" href="javascript:void(0)" (click)="onChangeTheme('light-m3')" tabindex="0"> <ng-container
			*ngTemplateOutlet="radioButtonIcon; context: { $implicit: 'light-m3', label: 'Light Mode' }">
		</ng-container></a>
	<!-- <a class="dropdown-item" href="javascript:void(0)" (click)="onChangeTheme('dark-m2')" tabindex="0"> <ng-container
			*ngTemplateOutlet="radioButtonIcon; context: { $implicit: 'dark-m2', label: 'M2 Dark' }">
		</ng-container></a>
	<a class="dropdown-item" href="javascript:void(0)" (click)="onChangeTheme('light-m2')" tabindex="0"> <ng-container
			*ngTemplateOutlet="radioButtonIcon; context: { $implicit: 'light-m2', label: 'M2 Light' }">
		</ng-container></a> -->
</li>

<!-- <li app-navbar-dropdown [icon]="'notifications'" title="Some Actions">
	<div postIcon>
		<span class="notification">3</span>
	</div>
	<a class="dropdown-item" href="javascript:void(0)">John Smith responded to your email</a>
	<a class="dropdown-item" href="javascript:void(0)">You have 5 new tasks</a>
	<a class="dropdown-item" href="javascript:void(0)">You're now friend with Andy</a>
</li> -->

<li app-navbar-dropdown icon="person" title="Account">
	<a class="dropdown-item" href="javascript:void(0)" (click)="onLogout()" tabindex="0"
		*ngIf="isAuthenticated$ | async">Logout</a>
	<a class="dropdown-item" href="javascript:void(0)" (click)="onLogin()" tabindex="0"
		*ngIf="!!!(isAuthenticated$ | async)">Login</a>
</li>

<ng-template #radioButtonIcon let-theme let-label="label">
	<mat-icon>{{ theme === model.saveTheme ? 'radio_button_checked' : 'radio_button_unchecked' }}</mat-icon>
	{{ label }}
</ng-template>
}
