import { Injectable } from '@angular/core';
import { NavbarDropdownComponent } from './navbar-dropdown.component';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class NavbarDropdownService {

	public toggleClicked$ = new Subject<NavbarDropdownComponent>();

	public onToggleClicked(sender: NavbarDropdownComponent) {
		this.toggleClicked$.next(sender);
	}

	constructor() { }
}
