import { AuthGuard as authenticationGuard } from '@auth0/auth0-angular';
import { InlightsRoute } from '@shared/models/shell/InlightsRoute';

export const clientsHomeRouteData: InlightsRoute[] = [

	{
		title: 'Home',
		icon: 'apartment',
		showInMenu: false,
		path: '',
		data: {
			allowAnonymousAccess: false,
			requiredClaims: [],
		},
		canActivate: [authenticationGuard],
		loadComponent: () => import('./client-home/client-home.component').then((m) => m.ClientHomeComponent),
	},
];
